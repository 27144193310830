import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import HomeDefault from "./pages/HomeDefault";
import AboutUs from "./pages/AboutUs";
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";
import Services from './pages/Services';
import Products from './pages/Products';
import Industries from './pages/Industries';
import Careers from './pages/Careers';
import Open_Source from './pages/Open_Source';
import TermsAndConditions from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NewsEvents from './pages/NewEvent';


// Import Css Here 
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeDefault}/>

                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>

                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>  
                    <Route path={`${process.env.PUBLIC_URL + "/services/:title"}`} exact component={Services}/>
                    <Route path={`${process.env.PUBLIC_URL + "/products"}`} exact component={Products}/>
                    <Route path={`${process.env.PUBLIC_URL + "/industries"}`} exact component={Industries}/>
                    <Route path={`${process.env.PUBLIC_URL + "/careers"}`} exact component={Careers}/>
                    <Route path={`${process.env.PUBLIC_URL + "/open_source"}`} exact component={Open_Source}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/terms"}`} exact component={TermsAndConditions}/>
                    <Route path={`${process.env.PUBLIC_URL + "/privacy"}`} exact component={PrivacyPolicy}/>
                    <Route path={`${process.env.PUBLIC_URL + "/news"}`} exact component={NewsEvents}/>







                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
