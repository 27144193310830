import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiCheck } from 'react-icons/fi';

const Careers = () => {
    return (
        <>
            <SEO title=" Careers || ORYXUN" />
            <Layout>
            <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Careers.</span></h4>
                                    <h2 className="title mt--10">Careers at ORYXUN.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Our Careers Page is your gateway to exciting opportunities at our company. Join our team and embark on a journey of professional growth and fulfillment. Explore a diverse range of roles and positions tailored to your skills and ambitions. Here you will find:</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span>Job Listings: Discover current job openings across various departments and locations.</li>
                                        <li><span className="icon"><FiCheck /></span>Company Culture: Learn about our values, mission, and what it's like to be part of our vibrant team.</li>
                                        <li><span className="icon"><FiCheck /></span>Benefits and Perks: Explore the competitive benefits packages and perks we offer to our employees.</li>  
                                        <li><span className="icon"><FiCheck /></span>Career Path: Understand the potential career paths and advancement opportunities within our organization.</li>
                                        <li><span className="icon"><FiCheck /></span>Employee Testimonials: Hear firsthand from our team members about their experiences and why they love working here.</li>
                                        <li><span className="icon"><FiCheck /></span>Application Process: Get insights into our application and interview processes, helping you prepare for success.</li>
                                        <li><span className="icon"><FiCheck /></span>Diversity and Inclusion:  Learn about our commitment to fostering diversity, equity, and inclusion in the workplace.</li>
                                        <li><span className="icon"><FiCheck /></span>FAQs:  Find answers to common questions about careers at our company.</li>

                                    </ul>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
        </Layout>
        </>
    )
}
export default Careers;