import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import ServiceItem from './ServiceItem';
import Detail from '../data/products/productsTwo.json';
import NewsSlider from './NewsSlider';
import ScrollAnimation from 'react-animate-on-scroll';

const NewsEvents = () => {
    const {title_sw,paragraph1_sw,image_sw,list_sw} = Detail[0];
    const {title_hw,paragraph1_hw,image_hw,list_hw} = Detail[1];

    return (
        <>
            <SEO title="News & Events || ORYXUN" />
            <Layout>
                <div className="main-content">
                    <div className="rwt-portfolio-area mt--50">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "What we are up to!"
                                            title = "ORYXUN NEWS & EVENTS"
                                            description = ""
                                        />
                                </div>
                            </div> 
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <NewsSlider />
                        </ScrollAnimation>                       

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default NewsEvents;
