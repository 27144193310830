import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../utils/script";
import NewsData from '../data/news/news.json';


const NewsSlider = () => {
    return (
        <div className="row bg-color-blackest p-5">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {NewsData.map((data, index) => (
                        <div key={index} className={`news-style `}>
                            <div className="row align-items-center row--20">
                                <div className="order-1 order-md-2 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.title}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h6 className="subtitle">{`Date: ${data.date}`}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-2 order-md-1 col-lg-4 col-md-4 ml--100 d-none d-md-block">
                                        <img className="w-100" src={`./images/news/${data.image}`} alt="Event Image" />
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default NewsSlider;




















