import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import ServiceData from '../data/service/serviceTwo.json';
import ServiceItem from './ServiceItem';
import Data from '../data/open source/open_source.json';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ServiceItem2 from './ServiceItem2';

const Open_Source = () => {
    const {image,title,paragraph1,paragraph2} = Data;
    return (
        <>
            <SEO title=" Open Source Center of Excellence || ORYXUN" />
            <Layout>
            {/* <SectionTitle
                textAlign = "text-center"
                radiusRounded = ""
                title = "Open Source Center of Excellence"
                /> */}
                <BreadcrumbOne
                    title="Open Source Center of Excellence"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About Us"
                />
                <div className="main-content mt--50">
                    <ServiceItem2 image={image} title={title} paragraph1={paragraph1} list={[]} paragraph2={paragraph2}/>
                </div>
            </Layout>
        </>
    )
}
export default Open_Source;