import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import SectionTitle from '../sectionTitle/SectionTitle';
import ContactData from "../../data/contact/contact.json"
const ContactOne = () => {
    const {phone,email,location}=ContactData;
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Phone Number</h4>
                                        {
                                            phone.map((phn,index)=>(<p><a href={`tel:+${phn}`}>{phn}</a></p>))
                                        }     
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        {
                                            email.map((eml,index)=>(<p><a href={`mailto:${eml}`}>{eml}</a></p>))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        {
                                            location.map((loc,index)=>(<p>{loc}</p>))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt--40 row--15 justify-content-center align-items-center">
                <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "Contact Form"
                            description = ""
                        />
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div> */}
            </div>
        </>
    )
}
export default ContactOne;