import React from 'react';
import {Link} from "react-router-dom";
import ServiceData from '../../data/service/serviceOne.json';
const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>

            <li className="has-droupdown"><Link to="#">About Us</Link>
                <ul className="submenu">
                    <li><Link to="/about-us">Our Company</Link></li>
                    <li><Link to="/open_source">Open Source Center of Excellence</Link></li>
                </ul>
            </li>
            <li className="has-droupdown"><Link to="#">Services</Link>
                <ul className="submenu">
                    {
                        ServiceData.map((val , i) => ( <li key={i}><Link to={`/services/${val.title}`}>{val.title}</Link></li> ))
                    }
                </ul>
            </li>
            <li><Link to="/products">Products</Link></li>
            <li><Link to="/industries">Industries</Link></li>

            {/* <li className="with-megamenu"><Link to="#">Products</Link>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-7 single-mega-item">             
                                <ul className="mega-menu-item">
                                    <li><span>Software Solutions</span></li>
                                    <li><Link to="/">Core Banking</Link></li>
                                    <li><Link to="/">Orylycs (Data Analytics Platform)</Link></li>
                                    <li><Link to="/">Actin (Accounting Package)</Link></li>
                                    <li><Link to="/">Sales and Inventory Management System</Link></li>
                                    <li><Link to="/">Monitoring and Evaluation Systems</Link></li>
                                    <li><Link to="/">Hospitality Management</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-5 single-mega-item">       
                                <ul className="mega-menu-item">
                                    <li><span>Hardware Solutions</span></li>
                                    <li><Link to="/">Mobile Devices</Link></li>
                                    <li><Link to="/">Embedded Systems</Link></li>
                                    <li><Link to="/">Other Devices</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li className="has-droupdown"><Link to="#">Industries</Link>
                <ul className="submenu">
                    <li><Link to="/">Finance</Link></li>
                    <li><Link to="/">Insurance</Link></li>
                    <li><Link to="/">Education</Link></li>
                    <li><Link to="/">Healthcare</Link></li>
                    <li><Link to="/">Retail</Link></li>
                    <li><Link to="/">Government/Public Sector</Link></li>
                    <li><Link to="/">Research</Link></li>
                
                </ul>
            </li> */}

            <li ><Link to="/careers">Careers</Link></li>
            <li ><Link to="/news">News & Events</Link></li>
            <li ><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
