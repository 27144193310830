import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const callToActionData = {
    title: "Ready to get unparalleled technology services and products?",
    subtitle: "Finest choice for your home & office",
    btnText: "Contact Us",
}

const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-city3.jpg)`}} data-black-overlay="8">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--0">{callToActionData.title}</h2>
                                <p>We help our clients with by offering technology talent and solutions at competitive prices on a global scale.</p>
                                <div className="call-to-btn text-center">
                                    <Link className="btn-default btn-icon" to="/contact">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;