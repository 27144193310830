import React from 'react';
import Layout from '../common/Layout';
import SEO from '../common/SEO';




const TermsAndConditions = () => {
    return (
        <>
        <SEO title="Terms of Service" />
        <Layout>
            <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1>Terms of Service</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            <div className='ml--50 mr--50'>
            
            <p className="font-size:12pt;"><strong><span className="font-family:Times;">Last Updated: 09/29/2023.</span></strong></p>
            <p className="font-size:14pt;"><strong><span className="font-family:Times;">PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR WEBSITE (THE &quot;SITE&quot;) OR OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITE OR OUR SERVICES.</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">The present terms and conditions (this &quot;</span><strong><span className="font-family:Times;">Agreement</span></strong><span className="font-family:Times;">&quot; or &quot;</span><strong><span className="font-family:Times;">Terms</span></strong><span className="font-family:Times;">&quot;) is a legal agreement between you and ORYXUN INC. (hereinafter&nbsp;</span><strong><span className="font-family:Times;">&quot; ORYXUN</span></strong><span className="font-family:Times;">&quot;), a company duly organized and validly existing, located at Green Poplar Loop, Clarksburg, Maryland 20871.</span><span className="font-family:Times;">&nbsp;This Agreement annuls and voids all previous agreements.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">OVERVIEW</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">The Site ( ORYXUN) is operated by ORYXUN. Throughout the Site, the terms &quot;</span><strong><span className="font-family:Times;">we</span></strong><span className="font-family:Times;">&quot;, &quot;</span><strong><span className="font-family:Times;">us</span></strong><span className="font-family:Times;">&quot; and &quot;</span><strong><span className="font-family:Times;">our</span></strong><span className="font-family:Times;">&quot; refer to ORYXUN. ORYXUNoffers this Site, including all information, tools and services available from this Site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">By visiting our Site and/or purchasing something from us, you engage in our &quot;Service&quot; and agree to be bound by the following terms and conditions, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms apply to all users of the Site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content. In the event of an inconsistency between this Agreement and any additional terms or policies referenced herein, the provisions of the additional terms or policies shall control.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Please read these Terms carefully before accessing or using our Site. By accessing or using any part of the Site, you agree to be bound by these Terms. If you do not agree to all the Terms of this Agreement, then you may not access the Site or use any Service. If these Terms are considered an offer, acceptance is expressly limited to these Terms.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Any new features or tools which are added to the current store shall also be subject to the Terms. You can review the most current version of the Terms at any time on this page. We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Site following the posting of any changes constitutes acceptance of those changes.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 1 - GENERAL TERMS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">By agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this Site.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You may not use our products or Site for any illegal or unauthorized purpose nor may you, in the use of our products or Site, violate any laws in your jurisdiction (including but not limited to motor vehicle laws).</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You must not transmit any worms or viruses or any code of a destructive nature.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">A breach or violation of any of the Terms will result in an immediate termination of your account and right to use our Service.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">We have the right, but not the obligation, to take any of the following actions in our sole discretion at any time and for any reason without giving you any prior notice:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">&nbsp;1. Restrict, suspend or terminate your access to all or any part of our Site;</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">&nbsp;2. Change, suspend or discontinue all or any part of our products or Site;</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">&nbsp;3. Refuse, move, or remove any content that is available on all or any part of our Site;</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">&nbsp;4. Deactivate or delete your accounts;</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">&nbsp;5. Establish general practices and limits concerning use of our Site.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You agree that we will not be liable to you or any third party for taking any of these actions.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You understand and agree that our Site may include communications such as service announcements and administrative or legal notices from us. Please note that you cannot opt out of receiving these notices.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of the Site, or access to the Site or any contact on the Site, without express written permission by us.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You may not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. ORYXUNcontent is not for resale. Use of the Site does not entitle users to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of ORYXUN and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of ORYXUN or our licensors except as expressly authorized by these Terms.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 2 - CREATING AN ACCOUNT</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Once you create an account with us, you are registered on the ORYXUN Site. The terms &quot;member,&quot; &quot;membership,&quot; and &quot;account&quot; all refer to this registration as a member on ORYXUN&apos;s Site. If you are merely surfing or browsing through the Site and have not yet created an account, your use of the Site is still subject to this Agreement; if you do not agree to this Agreement, do not use the Site.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">When you create an account, you will provide a unique username and email.</span><span className="font-family:Times;">&nbsp;We will also ask you to create a password.</span><span className="font-family:Times;">&nbsp;Because any activities that occur under your username or password are your responsibility it is important for you to keep your username and/or password secure. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that ORYXUN is not responsible for third party access to your account that results from theft or misappropriation of your account. Notify us immediately if you believe that someone has used your username, email, or password without your authorization.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Furthermore, the registering party hereby acknowledges, understands and agrees to:</span></p>
           
            <p className="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">a) furnish factual, correct, current and complete information with regards to yourself as may be requested by the data registration process, and</span></p>
            
            <p className="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">b) maintain and promptly update your registration and profile information in an effort to maintain accuracy and completeness at all times.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">If anyone knowingly provides any information of a false, untrue, inaccurate or incomplete nature, ORYXUN INC.&nbsp;</span><span className="font-family:Times;">will have sufficient grounds and rights to suspend or terminate the member in violation of this aspect of the Agreement, and as such refuse any and all current or future use of ORYXUN INC.</span><span className="font-family:Times;">Services, or any portion thereof.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 3 - CONDUCT</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">As a user or member of the Site, you herein acknowledge, understand and agree that all information, text, software, data, photographs, music, video, messages, tags or any other content, whether it is publicly or privately posted and/or transmitted, is the expressed sole responsibility of the individual from whom the content originated. In short, this means that you are solely responsible for any and all content posted, uploaded, emailed, transmitted or otherwise made available by way of the ORYXUN Services, and as such, we do not guarantee the accuracy, integrity or quality of such content. It is expressly understood that by use of our Services, you may be exposed to content including, but not limited to, any errors or omissions in any content posted, and/or any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or otherwise made available by ORYXUN.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Furthermore, you herein agree not to make use of ORYXUN INC.&apos;s Services for the purpose of:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">a) uploading, posting, emailing, transmitting, or otherwise making available any content that shall be deemed unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or invasive of another&apos;s privacy or which is hateful, and/or racially, ethnically, or otherwise objectionable;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">b) causing harm to minors in any manner whatsoever;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) impersonating any individual or entity, including, but not limited to, any ORYXUN</span><span className="font-family:Times;">officials, forum leaders, guides or hosts or falsely stating or otherwise misrepresenting any affiliation with an individual or entity;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">d) forging captions, headings or titles or otherwise offering any content that you personally have no right to pursuant to any law nor having any contractual or fiduciary relationship with;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">e) uploading, posting, emailing, transmitting or otherwise offering any such content that may infringe upon any patent, copyright, trademark, or any other proprietary or intellectual rights of any other party;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">f) uploading, posting, emailing, transmitting or otherwise offering any content that you do not personally have any right to offer pursuant to any law or in accordance with any contractual or fiduciary relationship;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">g) uploading, posting, emailing, transmitting, or otherwise offering any unsolicited or unauthorized advertising, promotional flyers, &quot;junk mail,&quot; &quot;spam,&quot; or any other form of solicitation, except in any such areas that may have been designated for such purpose;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">h) uploading, posting, emailing, transmitting, or otherwise offering any source that may contain a software virus or other computer code, any files and/or programs which have been designed to interfere, destroy and/or limit the operation of any computer software, hardware, or telecommunication equipment;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">i) disrupting the normal flow of communication, or otherwise acting in any manner that would negatively affect other users&apos; ability to participate in any real time interactions;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">j) interfering with or disrupting any ORYXUN INC.Services, servers and/or networks that may be connected or related to our website, including, but not limited to, the use of any device software and/or routine to bypass the robot exclusion headers;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">k) intentionally or unintentionally violating any local, state, federal, national or international law, including, but not limited to, rules, guidelines, and/or regulations decreed by the U.S. Securities and Exchange Commission, in addition to any rules of any nation or other securities exchange, that would include without limitation, the New York Stock Exchange, the American Stock Exchange, or the NASDAQ, and any regulations having the force of law;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">l) providing informational support or resources, concealing and/or disguising the character, location, and or source to any organization delegated by the United States government as a &quot;foreign terrorist organization&quot; in accordance to Section 219 of the Immigration Nationality Act;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">m) &quot;stalking&quot; or with the intent to otherwise harass another individual; and/or</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">n) collecting or storing of any personal data relating to any other member or user in connection with the prohibited conduct and/or activities which have been set forth in the aforementioned paragraphs.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">herein reserves the right to pre-screen, refuse and/or delete any content currently available through our Services. In addition, we reserve the right to remove and/or delete any such content that would violate the Terms or which would otherwise be considered offensive to other visitors, users and/or members.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">herein reserves the right to access, preserve and/or disclose member account information and/or content if it is requested to do so by law or in good faith belief that any such action is deemed reasonably necessary for:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">a) compliance with any legal process;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">b) enforcement of the Terms;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) responding to any claim that therein contained content is in violation of the rights of any third party;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">d) responding to requests for customer service; or</span></p>
           
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">e) protecting the rights, property or the personal safety of ORYXUN INC., its visitors, users and members, including the general public.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">herein reserves the right to include the use of security components that may permit digital information or material to be protected, and that such use of information and/or material is subject to usage guidelines and regulations established by ORYXUN INC. or any other content providers supplying content services to ORYXUN INC.. You are hereby prohibited from making any attempt to override or circumvent any of the embedded usage rules in our Services. Furthermore, unauthorized reproduction, publication, distribution, or exhibition of any information or materials supplied by our Services, despite whether done so in whole or in part, is expressly prohibited.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 4 - GLOBAL USE; EXPORT/IMPORT COMPLIANCE</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Due to the global nature of the internet, through the use of our network you hereby agree to comply with all local rules relating to online conduct and that which is considered acceptable content. Uploading, posting and/or transferring of software, technology and other technical data may be subject to the export and import laws of the United States and possibly other countries. Through the use of our network, you thus agree to comply with all applicable export and import laws, statutes and regulations, including, but not limited to, the Export Administration Regulations (</span><a href="http://www.access.gpo.gov/bis/ear/ear_data.html" className="text-decoration:none;"><u><span className="font-family:Times;">http://www.access.gpo.gov/bis/ear/ear_data.html</span></u></a><span className="font-family:Times;">), as well as the sanctions control program of the United States (</span><a href="http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx" className="text-decoration:none;"><u><span className="font-family:Times;">http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</span></u></a><span className="font-family:Times;">). Furthermore, you state and pledge that you:</span></p>
            
            <p className="font-size:12pt;"><span className="font-family:Times;">a) are not on the list of prohibited individuals which may be identified on any government export exclusion report (</span><a href="http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm" className="text-decoration:none;"><u><span className="font-family:Times;">http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm</span></u></a><span className="font-family:Times;">) nor a member of any other government which may be part of an export-prohibited country identified in applicable export and import laws and regulations;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">b) agree not to transfer any software, technology or any other technical data through the use of our network Services to any export-prohibited country;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) agree not to use our website network Services for any military, nuclear, missile, chemical or biological weaponry end uses that would be a violation of the U.S. export laws; and</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">d) agree not to post, transfer nor upload any software, technology or any other technical data which would be in violation of the U.S. or other applicable export and/or import laws.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 5 - SUBMITTED CONTENT</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">shall not lay claim to ownership of any content submitted by any visitor, member, or user, nor make such content available for inclusion on our website Services. Therefore, you hereby grant and allow for ORYXUN INC. the below listed worldwide, royalty-free and non-exclusive licenses, as applicable:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">a) The content submitted or made available for inclusion on the publicly accessible areas of ORYXUN INC.&apos;s Sites, the license provided to permit to use, distribute, reproduce, modify, adapt, publicly perform and/or publicly display said Content on our network Services is for the sole purpose of providing and promoting the specific area to which this content was placed and/or made available for viewing. This license shall be available so long as you are a member of ORYXUN INC.&apos;s sites, and shall terminate at such time when you elect to discontinue your membership.</span></p>
            <p className="margin-top:0pt; margin-bottom:0pt; text-indent:36pt; text-align:justify;">&nbsp;</p>
            <p className="font-size:12pt;"><span className="font-family:Times;">b) Photos, audio, video and/or graphics submitted or made available for inclusion on the publicly accessible areas of ORYXUN INC.&apos;s sites, the license provided to permit to use, distribute, reproduce, modify, adapt, publicly perform and/or publicly display said Content on our network Services are for the sole purpose of providing and promoting the specific area in which this content was placed and/or made available for viewing. This license shall be available so long as you are a member of ORYXUN INC.&apos;s sites and shall terminate at such time when you elect to discontinue your membership.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) For any other content submitted or made available for inclusion on the publicly accessible areas of ORYXUN INC.&apos;s sites, the continuous, binding and completely sub-licensable license which is meant to permit to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform and/or publicly display said content, whether in whole or in part, and the incorporation of any such Content into other works in any arrangement or medium current used or later developed.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Those areas which may be deemed &quot;publicly accessible&quot; areas of ORYXUN INC.&apos;s Sites are those such areas of our network properties which are meant to be available to the general public, and which would include message boards and groups that are openly available to both users and members. However, those areas which are not open to the public, and thus available to members only, would include our mail system and instant messaging.</span></p>
           
            <p className="font-size:12pt;"><strong><u><span className="font-family:Times;">CONTRIBUTIONS TO COMPANY WEBSITE</span></u></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">may provide an area for our user and members to contribute feedback to our website. When you submit ideas, documents, suggestions and/or proposals (&quot;</span><strong><span className="font-family:Times;">Contributions</span></strong><span className="font-family:Times;">&quot;) to our site, you acknowledge and agree that:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">a) your contributions do not contain any type of confidential or proprietary information;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">b) ORYXUN</span><span className="font-family:Times;">shall not be liable or under any obligation to ensure or maintain confidentiality, expressed or implied, related to any Contributions;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) ORYXUN</span><span className="font-family:Times;">shall be entitled to make use of and/or disclose any such Contributions in any such manner as they may see fit;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">d) the contributor&apos;s Contributions shall automatically become the sole property of ORYXUN; and</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">e) ORYXUN</span><span className="font-family:Times;">is under no obligation to either compensate or provide any form of reimbursement in any manner or nature.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 6 - INDEMNITY</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">All users and/or members agree to insure and hold ORYXUN INC., our subsidiaries, affiliates, agents, employees, officers, partners and/or licensors blameless or not liable for any claim or demand, which may include, but is not limited to, reasonable attorney fees made by any third party which may arise from any content a&nbsp;</span><span className="font-family:Times;">member or user of our Site may submit, post, modify, transmit or otherwise make available through our Services, the use of ORYXUN Services or your connection with these Services, your violations of the Terms of Service and/or your violation of any such rights of another person.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 7 - COMMERCIAL REUSE OF SERVICES</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">The member or user herein agrees not to replicate, duplicate, copy, trade, sell, resell nor exploit for any commercial reason any part, use of, or access to ORYXUN&apos;s sites.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 8 - MODIFICATIONS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.reserves the right at any time it may deem fit, to modify, alter and or discontinue, whether temporarily or permanently, our service, or any part thereof, with or without prior notice. In addition, we shall not be held liable to you or to any third party for any such alteration, modification, suspension and/or discontinuance of our Services, or any part thereof.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 9 - TERMINATION</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">As a member of ORYXUN, you may cancel or terminate your account, associated email address and/or access to our Services by submitting a cancellation or termination request to .</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">As a member, you agree that ORYXUN INC. may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your account, any email associated with your account, and access to any of our Services. The cause for such termination, discontinuance, suspension and/or limitation of access shall include, but is not limited to:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">a) any breach or violation of our Terms or any other incorporated agreement, regulation and/or guideline;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">b) by way of requests from law enforcement or any other governmental agencies;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) the discontinuance, alteration and/or material modification to our Services, or any part thereof;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">d) unexpected technical or security issues and/or problems;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">e) any extended periods of inactivity;</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">f) any engagement by you in any fraudulent or illegal activities; and/or</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">g) the nonpayment of any associated fees that may be owed by you in connection with your ORYXUN</span><span className="font-family:Times;">account Services.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or limitations of access for cause shall be made at our sole discretion and that we shall not be liable to you or any other third party with regards to the termination of your account, associated email address and/or access to any of our Services.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">The termination of your account with ORYXUN shall include any and/or all of the following:</span></p>
           
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">a) the removal of any access to all or part of the Services offered within ORYXUN;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">b) the deletion of your password and any and all related information, files, and any such content that may be associated with or inside your account, or any part thereof; and</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">c) the barring of any further use of all or part of our Services.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 10 - LINKS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Either ORYXUN INC.&nbsp;</span><span className="font-family:Times;">or any third parties may provide links to other websites and/or resources. Thus, you acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and as such, we do not endorse nor are we responsible or liable for any content, products, advertising or any other materials, on or available from such third-party sites or resources. Furthermore, you acknowledge and agree that ORYXUN INC.&nbsp;</span><span className="font-family:Times;">shall not be responsible or liable, directly or indirectly, for any such damage or loss which may be a result of, caused or allegedly to be caused by or in connection with the use of or the reliance on any such content, goods or Services made available on or through any such site or resource.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 11 - PROPRIETARY RIGHTS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You do hereby acknowledge and agree that ORYXUN INC.&apos;s Services and any essential software that may be used in connection with our Services (&quot;Software&quot;) shall contain proprietary and confidential material that is protected by applicable intellectual property rights and other laws. Furthermore, you herein acknowledge and agree that any Content which may be contained in any advertisements or information presented by and through our Services or by advertisers is protected by copyrights, trademarks, patents or other proprietary rights and laws. Therefore, except for that which is expressly permitted by applicable law or as authorized by ORYXUN INC. or such applicable licensor, you agree not to alter, modify, lease, rent, loan, sell, distribute, transmit, broadcast, publicly perform and/or created any plagiaristic works which are based on ORYXUN INC.&nbsp;</span><span className="font-family:Times;">Services (e.g. Content or Software), in whole or part.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">hereby grants you a personal, non-transferable and non-exclusive right and/or license to make use of the object code or our Software on a single computer, as long as you do not, and shall not, allow any third party to duplicate, alter, modify, create or plagiarize work from, reverse engineer, reverse assemble or otherwise make an attempt to locate or discern any source code, sell, assign, sublicense, grant a security interest in and/or otherwise transfer any such right in the Software. Furthermore, you do herein agree not to alter or change the Software in any manner, nature or form, and as such, not to use any modified versions of the Software, including and without limitation, for the purpose of obtaining unauthorized access to our Services. Lastly, you also agree not to access or attempt to access our Services through any means other than through the interface which is provided by ORYXUN INC. for use in accessing our Services.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 12 - WARRANTY DISCLAIMERS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">a) THE USE OF ORYXUN INC.</span><span className="font-family:Times;">SERVICES AND SOFTWARE ARE AT THE SOLE RISK BY YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN &quot;AS IS&quot; AND/OR &quot;AS AVAILABLE&quot; BASIS. ORYXUN INC. AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">b) ORYXUN INC.</span><span className="font-family:Times;">AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i) ORYXUN INC.</span><span className="font-family:Times;">SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) ORYXUN INC.&nbsp;</span><span className="font-family:Times;">SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE ORYXUN INC.&nbsp;</span><span className="font-family:Times;">SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE CORRECTED.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">c) ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF ORYXUN INC.</span><span className="font-family:Times;">SERVICES OR SOFTWARE SHALL BE ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">d) NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE OBTAINED BY YOU FROM ORYXUN INC.&nbsp;</span><span className="font-family:Times;">OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">e) A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME DEGREE OF EPILEPTIC SEIZURE WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS THAT MAY BE CONTAINED ON A COMPUTER SCREEN OR WHILE USING OUR SERVICES. CERTAIN CONDITIONS MAY INDUCE A PREVIOUSLY UNKNOWN CONDITION OR UNDETECTED EPILEPTIC SYMPTOM IN USERS WHO HAVE SHOWN NO HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD YOU, ANYONE YOU KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC CONDITION, PLEASE CONSULT A PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES: DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 13 - LIMITATION OF LIABILITY</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT ORYXUN INC. AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:</span></p>
           
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">a) THE USE OR INABILITY TO USE OUR SERVICE;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">b) THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">c) UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">d) STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">e) AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 14 - RELEASE</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">In the event you have a dispute, you agree to release ORYXUN INC.(and its officers, directors, employees, agents, parent subsidiaries, affiliates, co-branders, partners and any other third parties) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising out of or in any way connected to such dispute.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 15 - NOTICE</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">may furnish you with notices, including those with regards to any changes to the Terms, including but not limited to email, regular mail, MMS or SMS, text messaging, postings on our website Services, or other reasonable means currently known or any which may be herein after developed. Any such notices may not be received if you violate any aspects of the Terms by accessing our Services in an unauthorized manner. Your acceptance of this Agreement constitutes your agreement that you are deemed to have received any and all notices that would have been delivered had you accessed our Services in an authorized manner.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 16 - INTELLECTUAL PROPERTY RIGHTS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You herein acknowledge, understand and agree that all of the ORYXUN INC.trademarks, copyright, trade name, service marks, and other ORYXUN INC.logos and any brand features, and/or product and service names are trademarks and as such, are and shall remain the property of ORYXUN INC.. You herein agree not to display and/or use in any manner the ORYXUN INC.logo or marks without obtaining ORYXUN INC.&apos;s prior written consent.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span><span className="font-family:Times;">will always respect the intellectual property of others, and we ask that all of our users do the same. With regards to appropriate circumstances and at its sole discretion, ORYXUN INC.</span><span className="font-family:Times;">may disable and/or terminate the accounts of any user who violates our Terms and/or infringes the rights of others. If you feel that your work has been duplicated in such a way that would constitute copyright infringement, or if you believe your intellectual property rights have been otherwise violated, you should provide to us the following information:</span></p>
           
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">a) The electronic or the physical signature of the individual that is authorized on behalf of the owner of the copyright or other intellectual property interest;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">b) A description of the copyrighted work or other intellectual property that you believe has been infringed upon;</span></p>
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">c) A description of the location of the site which you allege has been infringing upon your work;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">d) Your physical address, telephone number, and email address;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">e) A statement, in which you state that the alleged and disputed use of your work is not authorized by the copyright owner, its agents or the law;</span></p>
            
            <p className="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><span className="font-family:Times;">f) And finally, a statement, made under penalty of perjury, that the aforementioned information in your notice is truthful and accurate, and that you are the copyright or intellectual property owner, representative or agent authorized to act on the copyright or intellectual property owner&apos;s behalf.</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">The ORYXUN INC.&nbsp;</span><span className="font-family:Times;">agent for notice of claims of copyright or other intellectual property infringement can be contacted as follows:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Mailing Address:</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Attn: Copyright Agent</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Green Poplar Loop</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Clarksburg, Maryland 20871</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Telephone: +1 (833) 748-3338</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Email:</span><span className="font-family:Times;">&nbsp;info@oryxun.com</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 17 - ENTIRE AGREEMENT</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">This Agreement constitutes the entire agreement between you and ORYXUN INC.and shall govern the use of our Services, superseding any prior version of this Agreement between you and us with respect to ORYXUN INC. Services. You may also be subject to additional terms and conditions that may apply when you use or purchase certain other ORYXUN INC. Services, affiliate Services, third-party content or third-party software.</span></p>
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 18 - CHOICE OF LAW AND FORUM</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">It is at the mutual agreement of both you and ORYXUN INC. with regard to the Agreement that the relationship between the parties shall be governed by the laws of the state of Maryland without regard to its conflict of law provisions and that any and&nbsp;</span><a name="bookmark_id.gjdgxs"></a><span className="font-family:Times;">all claims, causes of action and/or disputes, arising out of or relating to the Agreement, or the relationship between you and ORYXUN INC., shall be filed within the courts having jurisdiction within the County of Montgomery, Maryland</span><span className="font-family:Times;">or the U.S. District Court located in said state. You and ORYXUN INC. agree to submit to the jurisdiction of the courts as previously mentioned, and agree to waive any and all objections to the exercise of jurisdiction over the parties by such courts and to venue in such courts.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 19 - WAIVER AND SEVERABILITY OF TERMS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">At any time, should ORYXUN INC.&nbsp;</span><span className="font-family:Times;">fail to exercise or enforce any right or provision of the Agreement, such failure shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties&apos; intentions as reflected in the provision, and the other provisions of the Agreement remain in full force and effect.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 20 - NO RIGHT OF SURVIVORSHIP NON-TRANSFERABILITY</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You acknowledge, understand and agree that your account is non-transferable and any rights to your ID and/or contents within your account shall terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated and all contents therein permanently deleted.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 21 - STATUTE OF LIMITATIONS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">You acknowledge, understand and agree that regardless of any statute or law to the contrary, any claim or action arising out of or related to the use of our Services or the Agreement must be filed within 1 year(s) after said claim or cause of action arose or shall be forever barred.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 22 - VIOLATIONS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Please report any and all violations of this Agreement to ORYXUN INC. as follows:</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Mailing Address:</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">ORYXUN INC.</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Green Poplar Loop</span></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Clarksburg, Maryland 20871</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Telephone: +1 (833) 748-3338</span></p>
            <p className="font-size:12pt;"></p>
            <p className="font-size:12pt;"><span className="font-family:Times;">Email: info@oryxun.com</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 23 - GOVERNMENT REQUESTS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">In order to cooperate with governmental requests, subpoenas or court orders, to protect our systems, or to ensure the integrity and operation of our business and systems, we may access and disclose any information we consider necessary or appropriate, including and without limitation, your information, IP address, and usage history.&nbsp;</span><span className="font-family:Times;">Our right to disclose any such information is governed by the terms of our Privacy Policy.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 24 - FOREIGN ACCESS OF SITE</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">The Site is controlled, operated and administered by ORYXUNfrom our offices within the USA. If you access the Site from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use ORYXUN&apos;s content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</span></p>
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 25 - ERRORS, INACCURACIES AND OMISSIONS</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Occasionally there may be information on our Site that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Site or on any related Site is inaccurate at any time without prior notice (including after you have submitted your order).</span></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">We undertake no obligation to update, amend or clarify information on the Site or on any related Site, including without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Site or on any related Site, should be taken to indicate that all information on the Site or on any related Site has been modified or updated.</span></p>
           
           
           
            <p className="font-size:16pt;"><strong><span className="font-family:Times;">SECTION 26 - PRIVACY POLICY</span></strong></p>
           
            <p className="font-size:12pt;"><span className="font-family:Times;">Every member&apos;s registration data and various other personal information are strictly protected by the ORYXUN INC. Online Privacy Policy (see the full Privacy Policy at https://www.oryxun.com/privacy_policy). As a member, you herein consent to the collection and use of the information provided, including the transfer of information within the United States and/or other countries for storage, processing or use by ORYXUN INC. and/or our subsidiaries and affiliates.</span></p>
            <p className="font-size:12pt;"></p>
           
            <p className="bottom: 10px; right: 10px; position: absolute;"><a href="https://wordtohtml.net" target="_blank" className="font-size:11px; color: #d0d0d0;">Converted to HTML with WordToHTML.net</a></p>
            </div>
        </Layout>
        </>
    )
}
export default TermsAndConditions;
