import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import CalltoActionSeven from '../elements/calltoaction/CalltoActionSeven';



const ServiceItem = ({title,image,paragraph1,list,paragraph2}) => {
    
    return (
            <div className="rn-splite-style2 ">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 ">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-right-content">
                                    <img src={`${image}`}  alt={`${title} Images`} />
                                </div>
                            </div>
                            <div className="col-lg-20 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        {title && <h4 className="title">{title}</h4>}
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">{paragraph1}</p>
                                        {
                                        list &&    
                                        <ul className="split-list">
                                            {
                                                list.map((val,i)=>(<li key={i}>{`- ${val}`}</li>))
                                            }
                                            
                                        </ul>
                                        }
                                    </ScrollAnimation>
                                    {paragraph2 && <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">{paragraph2}</p>
                                    </ScrollAnimation>}
                                </div>
                        </div>
                    </div>
                </div>
            {/* <CalltoActionSeven/> */}
            </div>
    )
}

export default ServiceItem
