import React from 'react'
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import VideoItem from "../elements/video/VideoItem";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import ServiceFive from '../elements/service/ServiceFive';
import ServiceTwo from '../elements/service/ServiceTwo';
import ServiceThree from '../elements/service/ServiceThree';
import ServiceFour from '../elements/service/ServiceFour';
import Service from '../elements/service/Service';
import TestimonialOne from '../elements/testimonial/TestimonialOne';
import SlipThree from '../elements/split/SlipThree';
import CalltoActionSeven from '../elements/calltoaction/CalltoActionSeven';
import CalltoAction from '../elements/calltoaction/CalltoAction';
import CalltoActionOne from '../elements/calltoaction/CalltoActionOne';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';
import CalltoActionFour from '../elements/calltoaction/CalltoActionFour';
import CalltoActionSix from '../elements/calltoaction/CalltoActionSix';
import CalltoActionEight from '../elements/calltoaction/CalltoActionEight';
import AboutTwo from '../elements/about/AboutTwo';
import ServiceData from '../data/service/serviceOne.json';
import IndustryData from '../data/industries/industriesOne.json';
import FooterTwo from '../common/footer/FooterTwo';
import FooterThree from '../common/footer/FooterThree';
import FooterFour from '../common/footer/FooterFour';



const HomeDefault = () => {
    
    return (
        <>
            <SEO title="ORYXUN" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne  btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-city3.jpg)`}}>
                 {/* <div className="slider-area slider-style-1 variation-default height-850 bg-transparent" >*/}
                    
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    {/* <span className="subtitle">DIGITAL CONSULTING AGENCY</span> */}
                                    <h1 className="title display-one">ORYXUN <br />  {" "} TECHNOLOGY SOLUTIONS.</h1>

                                    {/* </h1> */}
                                    <p className="description">We offer unparalleled technology talent and solutions at competitive prices on a global scale.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/contact">CONTACT US <i className="icon"><FiArrowRight /></i></Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Our Services"
                                    description = "Our dedicated team is here to provide exceptional services tailored to your needs,<br/>ensuring your success in every endeavor."
                                    />
                            </div>
                        </div>
                        <ServiceTwo 
                            cardStyle = "card-style-1"
                            textAlign = "text-start"
                            ServiceList = {ServiceData}
                            readMore = {true}
                            
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                {/* Start Products Area  */}
                <div className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Exploring Our Product Range"
                                    title = "Our Products"
                                    description = "Discover a range of top-quality products designed <br/> to meet your specific requirements and elevate your business."
                                />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio"  />
                    </div>
                </div>
                {/* End Products Area  */}

                <Separator />
                <AboutTwo />

                {/* Start Industry Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Expertise in Diverse Sectors"
                                    title = "Tailored Solutions for Various Industries."
                                    description = "Explore how our tailored solutions drive innovation and <br/> success across diverse industries."
                                    />
                            </div>
                        </div>
                        <ServiceTwo 
                            cardStyle = "card-style-1"
                            textAlign = "text-start"
                            ServiceList = {IndustryData}
                            readMore = {false}
                            />
                    </div>
                </div>
                {/* End Industry Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                {/* <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "What People Say About Us."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div> */}
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Elements Area  */}

                <FooterFour />        

            </main>
            
        </>
    )
}
export default HomeDefault

