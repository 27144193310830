import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ServiceData from '../data/service/serviceTwo.json';
import SlpitOne from '../elements/split/SlpitOne';
import ServiceItem from './ServiceItem';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';

const Services = ({match: {params: {title}}}) => {
    const {image,name,paragraph1,list,paragraph2} = ServiceData[title];
    return (
        <>
            <SEO title=" Services || ORYXUN" />
            <Layout>

                <div className="main-content mt--50">
                    <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "What we can do for you"
                    title = "Our Services"
                    />
                    <div className='mt--50'>
                    <ServiceItem image={image} title={name} paragraph1={paragraph1} list={list} paragraph2={paragraph2}/>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Services;