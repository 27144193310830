import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BlogProp from '../components/blog/itemProp/BlogProp';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ServiceTwo from '../elements/service/ServiceTwo';
import IndustryData from '../data/industries/industriesOne.json';
import Detail from '../data/industries/industriesTwo.json';
import ServiceItem from './ServiceItem';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';


const Industries = () => {
    const {title,paragraph1,image,list} = Detail;
    return (
        <>
            <SEO title="Industries || ORYXUN" />
            <Layout>
                <BreadcrumbOne
                    title="Our Industries"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Industries"
                />
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="mt--50">
                     <ServiceItem image={image} title={title} paragraph1={paragraph1} list={list} paragraph2=""/>
                     </div>
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Our Expertise in Diverse Sectors"
                                            title = "Tailored Solutions for Various Industries."
                                            description = ""
                                        />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <ServiceTwo
                                    cardStyle = "card-style-1"
                                    textAlign = "text-start"
                                    ServiceList = {IndustryData}
                                    readMore = {false}
                                    />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Industries;