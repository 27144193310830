import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import ServiceItem from './ServiceItem';
import Detail from '../data/products/productsTwo.json';
import ServiceItem2 from './ServiceItem2';
import Separator from "../elements/separator/Separator";

const Products = () => {
    const {title_sw,paragraph1_sw,image_sw,list_sw} = Detail[0];
    const {title_hw,paragraph1_hw,image_hw,list_hw} = Detail[1];

    return (
        <>
            <SEO title="Products|| ORYXUN" />
            <Layout>
                <BreadcrumbOne
                    title="Our Products"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Products"
                />
                <div className="mt--50">
                <ServiceItem image={image_sw} title={title_sw} paragraph1={paragraph1_sw} list={list_sw} paragraph2=""/>
                </div>
                <Separator/>
                <div className="mt--50">
                <ServiceItem2 image={image_hw} title={title_hw} paragraph1={paragraph1_hw} list={list_hw} paragraph2=""/>
                </div>
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Explore products tailored for your business needs!"
                                            title = "Our Products"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Products;
